import { Box, Link, Typography } from "@mui/material";
import React from "react";

const InPersonTherapist = ({ memberName }) => {
  const reactivationLink =
    "https://app.treatmyocd.com/login?redirectTo=/therapy/reactivate";

  return (
    <Box mt={3}>
      <Typography variant="h6" component="p" fontWeight="700" mt={1.5}>
        Dear {memberName},
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We want to express our sincere gratitude for your time as a Member of
        NOCD Therapy. You have now been officially discharged from your NOCD
        therapist, and we are truly thankful that you chose us to support you in
        your journey. Please know that you are always welcome to return in the
        future. We built NOCD to be a lasting resource for Members like you
        because we understand that OCD and related conditions can ebb and flow.
        Our commitment to you remains strong.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We understand that you are exploring in-person therapy. Sometimes people
        prefer in-person therapy to virtual therapy, especially since people
        have been doing therapy that way for a long time. If you have any
        concerns about the effectiveness of virtual therapy, please access this
        article:{" "}
        <Link
          href="https://www.treatmyocd.com/blog/could-virtual-erp-be-the-new-standard-of-treatment-for-ocd"
          target="_blank"
        >
          Could virtual ERP be the new standard of treatment for OCD?
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        ERP done virtually can be even more effective than in-person. This is
        because exposures and triggers happen in your everyday life and virtual
        therapy allows you to “take your therapist with you” to complete
        exposures in your own home, or in any personal or public space where OCD
        typically arises.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Ultimately, we hope that you have effective treatment that works for
        you.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        In the event that you would like to return to NOCD at some point, we are
        here. Please keep our contact handy; you can also reach us by email at{" "}
        <Link href="mailto:care@nocdhelp.com" target="_blank">
          care@nocdhelp.com
        </Link>
        . We have an open-door policy, and we truly mean it. Yours to use at any
        time:{" "}
        <Link href={reactivationLink} target="_blank">
          Schedule Therapy Session
        </Link>
        . You can book with the same therapist or choose another provider if you
        prefer.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        If a tough situation arises and you need to talk to someone immediately,
        know that you are not alone. Here are several places that are
        well-equipped to help with crisis support:{" "}
        <Link
          href="https://www.treatmyocd.com/emergency-resources"
          target="_blank"
        >
          https://www.treatmyocd.com/emergency-resources
        </Link>
        .
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Thank you again for allowing us to be part of your journey.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Warmly,
      </Typography>

      <Typography variant="body1" component="p" fontWeight="700" mt={1.5}>
        NOCD Clinical Leadership Team
      </Typography>
    </Box>
  );
};

export default InPersonTherapist;
