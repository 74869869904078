import { Box, Link, Typography } from "@mui/material";
import React from "react";

const AchievedOutcomes = ({ memberName }) => {
  const reactivationLink =
    "https://app.treatmyocd.com/login?redirectTo=/therapy/reactivate";

  return (
    <Box mt={3}>
      <Typography variant="h6" component="p" fontWeight="700" mt={1.5}>
        Dear {memberName},
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We want to express our gratitude for your time as a Member of NOCD
        Therapy and confirm that you have now been officially discharged from
        your NOCD therapist. We are thankful that you chose us for your care and
        remind you that you are always welcome to return in the future. Your
        treatment journey and long-term well-being are our top priorities, and
        we want you to know that our support for you never ends. We built NOCD
        to support members like you for the long term because OCD and related
        conditions can ebb and flow. Our dedication to you remains strong
        throughout.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        You put in incredible effort to overcome what you were going through and
        don’t need to wait for a flare-up in symptoms to reach out. Connecting
        with your therapist and our team is always welcome, and you are never
        letting anyone down by doing so. Effective OCD treatment includes
        ongoing maintenance due to the nature of OCD and related conditions.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        If you find yourself needing help again, we are here. Please keep our
        contact handy; you can also reach us by email at{" "}
        <Link href="mailto:care@nocdhelp.com" target="_blank">
          care@nocdhelp.com
        </Link>
        . If a tough situation arises and you need to talk to someone
        immediately, know that you are not alone. Here are several resources
        that are well-equipped to provide crisis support:{" "}
        <Link
          href="https://www.treatmyocd.com/emergency-resources"
          target="_blank"
        >
          https://www.treatmyocd.com/emergency-resources
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We want to assure you that when we say we have an open-door policy, we
        truly mean it.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Yours to use at any time:{" "}
        <Link href={reactivationLink} target="_blank">
          Schedule Therapy Session
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Thank you again for including us in your journey.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Warmly,
      </Typography>

      <Typography variant="body1" component="p" fontWeight="700" mt={1.5}>
        NOCD Clinical Leadership Team
      </Typography>
    </Box>
  );
};

export default AchievedOutcomes;
