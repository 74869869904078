import { Box, Link, Typography } from "@mui/material";
import React from "react";

const ReferHloc = ({ memberName }) => {
  return (
    <Box mt={3}>
      <Typography variant="h6" component="p" fontWeight="700" mt={1.5}>
        Dear {memberName},
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We want to express our sincere gratitude for your time as a Member of
        NOCD Therapy. You have now been officially discharged from your NOCD
        therapist, and we are truly thankful that you chose us to support you in
        your journey. Please know that you are always welcome to return in the
        future. We built NOCD to be a lasting resource for Members like you
        because we understand that OCD and related conditions can ebb and flow.
        Our commitment to you remains strong.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We understand that your NOCD provider recommended a higher level of care
        before returning to NOCD treatment. As the Clinical Leadership Team, we
        care deeply about your referral experience and how you are doing now. If
        you are willing to share, we would love to hear from you to ensure you
        are receiving the care and support you deserve, and we hope to be able
        to work with you again in the near future. Please feel free to respond
        to this email directly or reach us at{" "}
        <Link href="mailto:care@nocdhelp.com" target="_blank">
          care@nocdhelp.com
        </Link>
        .
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        If a difficult situation arises and you need immediate support, remember
        that you are not alone. Here are several resources that can provide
        crisis support:{" "}
        <Link
          href="https://www.treatmyocd.com/emergency-resources"
          target="_blank"
        >
          https://www.treatmyocd.com/emergency-resources
        </Link>
        .
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Thank you again for allowing us to be part of your journey.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Warmly,
      </Typography>

      <Typography variant="body1" component="p" fontWeight="700" mt={1.5}>
        NOCD Clinical Leadership Team
      </Typography>
    </Box>
  );
};

export default ReferHloc;
