import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";

import AchievedOutcomes from "./EmailTemplatesPreview/AchievedOutcomes";
import FinancialConcerns from "./EmailTemplatesPreview/FinancialConcerns";
import InPersonTherapist from "./EmailTemplatesPreview/InPersonTherapist";
import NoLongerInterested from "./EmailTemplatesPreview/NoLongerInterested";
import Other from "./EmailTemplatesPreview/Other";
import OtherPrimary from "./EmailTemplatesPreview/OtherPrimary";
import ReferHloc from "./EmailTemplatesPreview/ReferHloc";
import Unresponsive from "./EmailTemplatesPreview/Unresponsive";

const getEmailTemplate = (dischargeReason: string, memberName: string) => {
  switch (dischargeReason) {
    case "Discharge - Member achieved outcomes and does not want maintenance support":
      return <AchievedOutcomes memberName={memberName ?? ""} />;
    case "Discharge - Member no longer interested in service (specify in DC Summary)":
      return <NoLongerInterested memberName={memberName ?? ""} />;
    case "Discharge - Member Unresponsive":
      return <Unresponsive memberName={memberName ?? ""} />;
    case "Discharge - Due to financial concerns":
      return <FinancialConcerns memberName={memberName ?? ""} />;
    case "Discharge - Refer Out to a higher level of care (IOP/PHP/RTC)":
      return <ReferHloc memberName={memberName ?? ""} />;
    case "Discharge - For treatment of other primary diagnosis (specify in DC summary)":
      return <OtherPrimary memberName={memberName ?? ""} />;
    case "Discharge - Refer out to see an in-person therapist at member request":
      return <InPersonTherapist memberName={memberName ?? ""} />;

    case "Discharge - Other (specify in DC Summary)":
      return <Other memberName={memberName ?? ""} />;
    default:
      return null;
  }
};
interface EmailTemplate {
  data: JSX.Element | null;
  error: string | null;
}

const getTemplate = (
  dischargeReason: string,
  memberName: string
): EmailTemplate => {
  if (!dischargeReason) {
    return {
      data: null,
      error: "No discharge reason provided",
    };
  }

  const template = getEmailTemplate(dischargeReason, memberName);

  if (!template) {
    return {
      data: null,
      error: "No email template found for this discharge reason",
    };
  }

  return {
    data: template,
    error: null,
  };
};

export default function DischargeEmailPreview({
  isOpen,
  onClose,
  dischargeReason,
  memberName,
}: {
  isOpen: boolean;
  onClose: () => void;
  dischargeReason: string;
  memberName: string;
}) {
  const template = getTemplate(dischargeReason, memberName);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle> Discharge Email Preview</DialogTitle>
      <DialogContent>
        <Box component={Paper} p={2} mt={0}>
          {template.data ??
            `[Unable to find email template: ${template?.error}]`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
