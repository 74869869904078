import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";

export interface IsDischargeActionNeededPayload {
  userId?: number;
  clinicianEmail?: string;
  dischargeReason?: string;
}

export const checkDischargeActionNeeded = (
  payload: IsDischargeActionNeededPayload,
  accessToken: string
) => {
  return api
    .get<0 | 1>(`/v1/check_discharge_action_needed`, {
      params: {
        user_id: payload.userId,
        clinician_email: payload.clinicianEmail,
        discharge_reason: payload.dischargeReason,
      },
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data === 1)
    .catch(transformAPIError);
};

const useIsDischargeActionNeeded = <T = boolean>(
  payload: IsDischargeActionNeededPayload = {},
  options: UseQueryOptions<boolean, Error, T> = {}
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "is-discharge-action-needed",
      payload.userId,
      payload.clinicianEmail,
      payload.dischargeReason,
    ],
    () => checkDischargeActionNeeded(payload, accessToken),
    {
      enabled: !!accessToken,
      ...options,
    }
  );
};

export default useIsDischargeActionNeeded;
