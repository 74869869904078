import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { TransferRequest } from "@features/transfers/types";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

export interface CreateTransferRequest {
  user_id: number;
  reason: string;
  reason_details: string;
  conditions: string[];
  is_current_clinician_continuing_treatment: boolean;
  current_clinician_treatment_end_date: string | null;
  action: string;
}

const useCreateTransferRequest = (): UseMutationResult<
  TransferRequest,
  Error,
  CreateTransferRequest
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const qc = useQueryClient();

  return useMutation(
    (payload) =>
      api
        .post<TransferRequest>(`/v1/transfers/requests`, payload, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then(({ data }) => data)
        .catch(transformAPIError),
    { onSuccess: () => qc.invalidateQueries("transfer-requests") }
  );
};

export default useCreateTransferRequest;
