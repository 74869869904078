import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const Unresponsive = ({ memberName }) => {
  const reactivationLink =
    "https://app.treatmyocd.com/login?redirectTo=/therapy/reactivate";
  return (
    <Box mt={3}>
      <Typography variant="h6" component="p" fontWeight="700" mt={1.5}>
        Dear {memberName},
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We hope you are having a good day. We wanted to take a moment and kindly
        let you know that you have been discharged from your NOCD therapist. We
        are grateful that you came to us for help and would like you to always
        feel welcome in the future.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        There may be times when you may be feeling well and other times when
        things are overwhelming, so if you find yourself needing help again,
        know we are here. Please keep our contact handy; you can also reach us
        by email at{" "}
        <Link href="mailto:care@nocdhelp.com">care@nocdhelp.com</Link> or
        utilize this link at any time:{" "}
        <Link href={reactivationLink} target="_blank">
          Schedule Therapy Session
        </Link>
        . If a tough situation comes up and you need to talk to someone
        immediately, know you are not alone. Here are several resources that are
        well-equipped to provide crisis support{" "}
        <Link
          href="https://www.treatmyocd.com/emergency-resources"
          target="_blank"
        >
          https://www.treatmyocd.com/emergency-resources
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        At some point, you may be interested in restarting therapy. You can
        follow these links to find a great therapist:
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary={
              <Link
                href="https://www.treatmyocd.com/therapists/overview"
                target="_blank"
              >
                NOCD Therapist Directory
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Link href="https://www.ocdinfo.com/providers" target="_blank">
                #KnowOCD Directory of Providers
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Link href="https://iocdf.org/" target="_blank">
                International OCD Foundation
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Link href={reactivationLink} target="_blank">
                Our self-service link to restart NOCD Therapy
              </Link>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" component="p" mt={2}>
        Thank you again for including us in your journey. We wish you the best
        and hope to hear from you whenever the need calls.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Warmly,
      </Typography>

      <Typography variant="body1" component="p" fontWeight="700" mt={1.5}>
        NOCD Clinical Leadership Team
      </Typography>
    </Box>
  );
};

export default Unresponsive;
