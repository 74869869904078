const getMemberFullName = (
  firstName: string,
  lastName: string,
  preferredName: string
) => {
  const formattedPreferredName = preferredName
    ? `"${preferredName}"`
    : undefined;

  return [firstName, formattedPreferredName, lastName]
    .filter(Boolean)
    .join(" ");
};

export default getMemberFullName;
