import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { isValid, parseISO } from "date-fns";
import { noop } from "lodash/fp";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import useCreateTransferRequest from "../hooks/useCreateTransferRequest";
import { TransferRequestCondition } from "../types";

const createTransferRequestSchema = yup.object().shape({
  reason: yup.string().required("Required"),
  reason_details: yup.string().required("Required"),
  conditions: yup.array().of(yup.string()).required("Required"),
  is_current_clinician_continuing_treatment: yup
    .string()
    .required("Required")
    .typeError("Required"),
});

const CreateTransferRequestDialog = ({
  userId,
  isOpen,
  onClose,
  onSuccess = noop,
  isForDischargeNote,
}: {
  userId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (result?: string) => void;
  isForDischargeNote?: boolean;
}) => {
  const formId = "create-transfer-request-form";

  const { mutateAsync: create } = useCreateTransferRequest();

  const { handleSubmit, formState, register, control, watch } = useForm({
    resolver: yupResolver(createTransferRequestSchema),
    defaultValues: {
      reason: "",
      reason_details: "",
      conditions: [TransferRequestCondition.OCD],
      is_current_clinician_continuing_treatment: null as string | null,
      current_clinician_treatment_end_date: null as string | null,
      action: "replace",
    },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit = handleSubmit((values) => {
    return toast.promise(
      create({
        user_id: userId,
        reason: values.reason,
        reason_details: values.reason_details,
        conditions: values.conditions,
        action: values.action,
        is_current_clinician_continuing_treatment:
          values.is_current_clinician_continuing_treatment === "1",
        current_clinician_treatment_end_date:
          values.is_current_clinician_continuing_treatment === "1"
            ? null
            : values.current_clinician_treatment_end_date,
      })
        .then(() => onSuccess("success"))
        .then(onClose),
      {
        loading: "Creating transfer request...",
        success: "Transfer request created!",
        error: "Failed to create transfer request",
      }
    );
  });

  const watchedIsCurrentClinicianContinuingTreatment = watch(
    "is_current_clinician_continuing_treatment"
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>New transfer request</DialogTitle>
      <DialogContent>
        <form
          id={formId}
          onSubmit={(e) => {
            e.stopPropagation();
            return onSubmit(e);
          }}
        >
          <Stack spacing={3} sx={{ mt: 2 }}>
            {isForDischargeNote && (
              <Alert severity="warning">
                <Typography fontWeight="bold">
                  There is no active transfer request for this members transfer
                  related discharge. Create one now to finish submitting this
                  discharge note.
                </Typography>
              </Alert>
            )}

            <Alert severity="info">
              Please be sure the member is aware of this transfer BEFORE
              submitting the request.
            </Alert>

            <TextField
              label="Reason"
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.reason?.message}
              helperText={errors.reason?.message}
              select
              {...register("reason")}
            >
              {[
                { value: "Member/Therapist Conflicting Availability" },
                { value: "Therapist Resignation/Leave" },
                { value: "Not a Good Fit (Therapist Match)" },
                { value: "Insurance/State Change" },
                { value: "Treatment for Specialty Condition" },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Please provide more details"
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
              rows={3}
              error={!!errors.reason_details?.message}
              helperText={errors.reason_details?.message}
              {...register("reason_details")}
            />

            <Controller
              name="is_current_clinician_continuing_treatment"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl error={!!fieldState?.error?.message}>
                  <FormLabel id="is_current_clinician_continuing_treatment_label">
                    Will the current clinician continue to treat the member
                    after the transfer?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="is_current_clinician_continuing_treatment_label"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>

                  {fieldState?.error?.message ? (
                    <FormHelperText error>
                      {fieldState.error.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />

            {watchedIsCurrentClinicianContinuingTreatment !== "1" ? (
              <Controller
                control={control}
                name="current_clinician_treatment_end_date"
                render={({ field, fieldState }) => (
                  <DatePicker
                    label="Anticipated treatment end date"
                    value={parseISO(field.value)}
                    onChange={(e) => {
                      if (isValid(e)) {
                        return field.onChange(e.toISOString());
                      }

                      return field.onChange(undefined);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(fieldState?.error?.message),
                        fullWidth: true,
                        helperText: fieldState?.error?.message,
                      },
                    }}
                  />
                )}
              />
            ) : null}

            <Controller
              control={control}
              name="conditions"
              render={({ field, fieldState }) => (
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                  error={!!fieldState?.error?.message}
                >
                  <FormLabel component="legend">
                    What is the member primarily seeking treatment for?
                  </FormLabel>
                  <RadioGroup
                    aria-label="Living-situation"
                    name={field.name}
                    value={String(field.value)}
                  >
                    {[
                      TransferRequestCondition.OCD,
                      TransferRequestCondition.HOARDING_DISORDER,
                      TransferRequestCondition.BFRBS,
                      TransferRequestCondition.PTSD,
                      TransferRequestCondition.BDD,
                    ].map((condition) => (
                      <FormControlLabel
                        key={condition}
                        control={
                          <Radio
                            checked={field.value.includes(condition)}
                            value={condition}
                            onChange={(e) => {
                              if (
                                field.value.includes(
                                  e.target.value as TransferRequestCondition
                                )
                              ) {
                                field.onChange([]);
                              } else {
                                field.onChange([e.target.value]);
                              }
                            }}
                          />
                        }
                        label={condition}
                      />
                    ))}
                  </RadioGroup>

                  {fieldState?.error?.message ? (
                    <FormHelperText error>
                      {fieldState.error.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" form={formId} disabled={isSubmitting}>
          Submit request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTransferRequestDialog;
