import { Box, Link, Typography } from "@mui/material";
import React from "react";

const NoLongerInterested = ({ memberName }) => {
  const reactivationLink =
    "https://app.treatmyocd.com/login?redirectTo=/therapy/reactivate";

  return (
    <Box mt={3}>
      <Typography variant="h6" component="p" fontWeight="700" mt={1.5}>
        Dear {memberName},
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We want to express our sincere gratitude for your time as a Member of
        NOCD Therapy. You have now been officially discharged from your NOCD
        therapist, and we are truly thankful that you chose us to support you in
        your journey. Please know that you are always welcome to return in the
        future. We built NOCD to be a lasting resource for Members like you
        because we understand that OCD and related conditions can ebb and flow.
        Our commitment to you remains strong.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        We understand you may no longer be interested in our services, and we
        would value your feedback. We know there are many reasons for moving on,
        and we want to take responsibility for any shortcomings that we can
        address. Please feel free to reply to this email with anything you feel
        comfortable sharing.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        If you ever find yourself needing help again, we are here for you.
        Please keep our contact handy; you can also reach us by email at{" "}
        <Link href="mailto:care@nocdhelp.com" target="_blank">
          care@nocdhelp.com
        </Link>
        . If a difficult situation arises and you need immediate support,
        remember that you are not alone. Here are several resources that can
        provide crisis support:{" "}
        <Link
          href="https://www.treatmyocd.com/emergency-resources"
          target="_blank"
        >
          https://www.treatmyocd.com/emergency-resources
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        When we say we have an open-door policy, we truly mean it. You are
        welcome to reconnect with us at any time. Yours to use at any time:{" "}
        <Link href={reactivationLink} target="_blank">
          Schedule Therapy Session
        </Link>
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Thank you again for allowing us to be part of your journey.
      </Typography>

      <Typography variant="body1" component="p" mt={2}>
        Warmly,
      </Typography>

      <Typography variant="body1" component="p" fontWeight="700" mt={1.5}>
        NOCD Clinical Leadership Team
      </Typography>
    </Box>
  );
};

export default NoLongerInterested;
